<template>
  <cp-general-modal
    ref="editTranslationModal"
    hide-footer
    hide-header
  >
    <div class="edit-translation-modal">
      <cp-button
        class="edit-translation-modal__btn-close"
        variant="outline"
        :disabled="isSaving"
        @click="$refs.editTranslationModal.hide()"
      >
        <i class="ion ion-ios-close fa-2x" />
      </cp-button>
      <div class="my-4">
        <h4 class="text-center mb-3">
          {{ $t('securitizeId.translations.editModal.title') }}
        </h4>
        <h5 class="text-center text-muted">
          {{ model.key }}
        </h5>
      </div>
      <div class="edit-translation-modal__language-container">
        <label>
          {{ $t('securitizeId.translations.editModal.EN') }}
        </label>
        <cp-text-area
          v-model="model.EN.content"
          :name="$t('securitizeId.translations.editModal.EN')"
        />
      </div>
      <div class="edit-translation-modal__language-container">
        <label>
          {{ $t('securitizeId.translations.editModal.ES') }}
        </label>
        <cp-text-area
          v-model="model.ES.content"
          :name="$t('securitizeId.translations.editModal.ES')"
        />
      </div>
      <div class="d-flex w-100 align-items-center justify-content-center mt-4">
        <cp-button
          class="edit-translation-modal__btn-save"
          variant="primary"
          :disabled="isSaving"
          @click="save"
        >
          {{ $t('securitizeId.translations.editModal.save') }}
        </cp-button>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpTextArea from '@/components/common/standalone-components/inputs/cp-textarea';
import CpButton from '@/components/common/standalone-components/cp-button';


const defaultModel = {
  EN: {},
  ES: {},
  JA: {},
  ZH: {},
  RU: {},
  FR: {},
};

export default {
  name: 'SecuritizeIdUpdateTranslation',
  components: {
    CpTextArea,
    CpGeneralModal,
    CpButton,
  },
  props: {
    translation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      model: defaultModel,
      originalData: defaultModel,
    };
  },
  methods: {
    ...mapActions('securitizeId', ['updateTranslation']),
    _show(item) {
      this.$refs.editTranslationModal.show();
      this.model = { ...this.model, ...item };
      this.originalData = JSON.parse(JSON.stringify(item));
    },
    save() {
      const values = ['EN', 'ES', 'JA', 'ZH', 'RU', 'FR']
        .filter(key => this.model[key].content !== (this.originalData[key] || {}).content)
        .map(key => ({
          language: key,
          content: this.model[key].content,
        }));

      if (values.length) {
        this.isSaving = true;
        this.updateTranslation({
          key: this.model.key,
          system: this.model.system,
          values,
        }).then(() => {
          this.$emit('translationUpdated');
          this.$refs.editTranslationModal.hide();
          this.model = defaultModel;
          this.originalData = defaultModel;
        }).finally(() => {
          this.isSaving = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .edit-translation-modal {
    padding: 0 1em;
    &__language-container {
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      .form-group {
        margin-bottom: unset;
        width: 85%;
      }

      label {
        width: 15%;
        margin-right: 0.5em;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &__btn-save {
      width: 250px;
      height: 42px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }

    &__btn-close {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
</style>
