<template>
  <span>
    <span :id="`${rowData.index}-${languageKey}`">
      {{ rowData.item[languageKey] ? rowData.item[languageKey].content : '-' }}
    </span>
    <b-tooltip
      :id="`tooltip-${rowData.index}-${languageKey}`"
      :ref="`tooltip-${rowData.index}-${languageKey}`"
      triggers="click"
      :target="`${rowData.index}-${languageKey}`"
      placement="top"
      custom-class="language-tooltip"
    >
      <span class="mr-3">
        {{ rowData.item[languageKey] ? rowData.item[languageKey].content : '-' }}
      </span>
      <i
        class="icon ion-ios-close"
        @click="$refs[`tooltip-${rowData.index}-${languageKey}`].$emit('close')"
      />
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'LanguageItem',
  props: {
    languageKey: {
      type: String, required: true,
    },
    rowData: {
      required: true, type: Object,
    },
  },
};
</script>

<style lang="scss">
  .language-tooltip {
    .arrow {
      display: none;
    }
    .tooltip-inner {
      background-color: #FFF;
      color: #617d8d;
      font-size: 11px;
      padding: 1em;
      border-radius: 4px;
      box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #ededee;
      text-align: left;
      max-width: 400px;

      div {
        display: flex;
        i {
          position: absolute;
          top: 0.5em;
          right: 0.5em;
          margin-left: 1em;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
</style>
