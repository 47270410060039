<template>
  <div>
    <div>
      <div class="header-block mb-5">
        <h4 class="font-weight-bold mb-0">
          <i class="ion ion-ios-globe mr-2" />
          {{ $t(`securitizeId.translations.titles.${activeTab ? 'csv' : 'translations'}`) }}
        </h4>
      </div>
    </div>
    <b-tabs
      v-model="activeTab"
      class="nav-tabs-top nav-responsive-sm"
      content-class="bg-transparent mt-4"
      active-nav-item-class="bg-transparent"
    >
      <b-tab
        :title="$t('securitizeId.translations.titles.translations')"
        @click="clearCsvData"
      >
        <cp-securitize-id-translations-table />
      </b-tab>
      <b-tab :title="$t('securitizeId.translations.titles.csv')">
        <import-csv ref="importCsv" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CpSecuritizeIdTranslationsTable from '@/pages/securitize-id/components/translations/table';
import ImportCsv from '@/pages/securitize-id/components/translations/import-csv';

export default {
  name: 'SecuritizeIdTranslations',
  metaInfo: {
    title: 'Translations',
  },
  components: {
    ImportCsv,
    CpSecuritizeIdTranslationsTable,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    clearCsvData() {
      this.$refs.importCsv.clearData();
    },
  },
};
</script>

<style lang="scss">
  .nav-tabs-top {
    div {
      .nav.nav-tabs {
        border-bottom: 1px solid #e5e5e5 !important;
      }
      .nav {
        .nav-link.active {
          border: none !important;
          border-color: #55bbc2 !important;
          border-bottom: 2px solid #55bbc2 !important;
        }
      }
    }
  }
</style>
