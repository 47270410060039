export default [
  {
    key: 'key', label: 'Key', sortable: true, tdClass: 'align-middle text-one-line',
  },
  {
    key: 'system', label: 'Domain', tdClass: 'align-middle text-one-line',
  },
  {
    key: 'EN', label: 'English', sortable: false, tdClass: 'align-middle text-one-line',
  },
  {
    key: 'ES', label: 'Spanish', sortable: false, tdClass: 'align-middle text-one-line',
  },
  { key: 'actions', label: '', tdClass: 'text-nowrap d-inline float-right' },
];
