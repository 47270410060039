<template>
  <div>
    <cp-table
      ref="cpTable"
      get-data-action="securitizeId/getSecuritizeIdTranslationsList"
      :fields="tableFields"
      :data-parser="parseData"
      :search-value="searchQueryParam"
      default-sort-direction="asc"
      rows-clickable
      is-fixed
      @tableDataUpdated="onTableDataUpdated"
    >
      <template
        slot="EN"
        slot-scope="{ rowData }"
      >
        <language-item
          language-key="EN"
          :row-data="rowData"
        />
      </template>
      <template
        slot="ES"
        slot-scope="{ rowData }"
      >
        <language-item
          language-key="ES"
          :row-data="rowData"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="primary"
            @click="editTranslation(rowData.item)"
          >
            <i class="fa fa-pencil-alt mr-2" /> Edit
          </cp-button>
        </div>
      </template>
    </cp-table>
    <securitize-id-update-translation
      ref="editTranslationModal"
      :translation="translationToEdit"
      @translationUpdated="onTranslationUpdated"
    />
  </div>
</template>

<script>
import CpTable from '@/components/shared/cp-table/index';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpTimeConverter from '@/mixins/time-converter';
import tableFields from './options';
import LanguageItem from '@/pages/securitize-id/components/translations/language-item';
import SecuritizeIdUpdateTranslation from '@/pages/securitize-id/components/translations/update-translation-modal';


export default {
  name: 'CpSecuritizeIdTranslationsTable',
  components: {
    SecuritizeIdUpdateTranslation,
    LanguageItem,
    CpTable,
    CpButton,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableFields,
      totalTableData: [],
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
      translationToEdit: {},
    };
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    parseData(data) {
      return data.map((item) => {
        const values = item.values.reduce((acc, value) => {
          const { language, ...other } = value;
          const systemObj = { ...acc[value.system], [language]: other };
          return { ...acc, [value.system]: systemObj };
        }, {});
        const mappedValues = Object.keys(values).map(key => ({ key: item.key, system: key, ...values[key] }));
        return mappedValues;
      }).flat();
    },
    editTranslation(translation) {
      this.$refs.editTranslationModal._show(translation);
    },
    onTranslationUpdated() {
      this.$refs.cpTable._updateTableData();
    },
  },
};
</script>

<style scoped lang="scss">
  .headers-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .input-group{
    width: auto;
  }
</style>

<style lang="scss">
  .text-one-line {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    div {
      span {
        display: flex;
      }
    }
  }
</style>
