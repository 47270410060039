<template>
  <b-card-body class="bg-white">
    <div class="d-flex mt-3">
      <div class="d-flex flex-column mx-5 align-items-center">
        <cp-button
          class="btn-clear-data"
          variant="link"
          icon="ion ion-ios-close"
          :disabled="!model.file.fileKey"
          @click="clearData"
        >
          {{ $t('securitizeId.translations.importTab.clearData') }}
        </cp-button>
        <cp-file-uploader
          id="import-translation-csv-uploader"
          ref="fileUploader"
          extension=".csv"
          class-style-override="small-footprint"
          show-default-message
          @fileLoading="() => { isUploading = true}"
          @onChange="fileUploaded"
        />
        <cp-button
          class="btn-continue"
          variant="primary"
          :disabled="submitDisabled"
          :is-loading="isImporting"
          @click="save"
        >
          {{ $t('securitizeId.translations.importTab.continue') }}
        </cp-button>
      </div>
      <div class="d-flex flex-column ml-5 pt-4">
        <b-form-checkbox
          v-model="model.overrideAll"
        >
          <span>{{ $t('securitizeId.translations.importTab.overrideAll') }}</span>
        </b-form-checkbox>
        <cp-button
          class="btn-continue w-auto mt-4"
          variant="outline-primary"
          icon="ion ion-ios-arrow-round-down"
          @click="downloadTranslations"
        >
          {{ $t('securitizeId.translations.importTab.downloadTranslations') }}
        </cp-button>
      </div>
    </div>
  </b-card-body>
</template>

<script>
import { mapActions } from 'vuex';
import CpFileUploader from '@/components/common/standalone-components/cp-file-uploader';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'ImportCsv',
  components: {
    CpFileUploader,
    CpButton,
  },
  data() {
    return {
      model: {
        file: {},
      },
      isImporting: false,
      isUploading: false,
    };
  },
  computed: {
    submitDisabled() {
      return this.isUploading || this.isImporting || !this.model.file.fileKey || !this.model.file.fileUrl;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['importTranslations']),
    fileUploaded({ fileKey, fileUrl }) {
      if (!fileKey || !fileUrl) {
        return;
      }
      this.model.file = { fileKey, fileUrl };
      this.isUploading = false;
    },
    downloadTranslations(e) {
      e.preventDefault();
      const link = document.createElement('a');
      link.href = 'https://docs.google.com/spreadsheets/d/1-E-9Pw6vKNp1g5-Q6gVj7G73inEABJTJyB8l0AGGP2w/gviz/tq?tqx=out:csv&sheet=translations';
      link.download = 'SecuritizeID-translations.csv';
      link.click();
    },
    save() {
      this.isImporting = true;
      const data = {
        updateAll: this.model.overrideAll,
        ...this.model.file,
      };
      this.importTranslations({ data })
        .then(() => { this.model.file = {}; })
        .finally(() => { this.isImporting = false; });
    },
    clearData() {
      this.model.file = {};
      this.$refs.fileUploader.clearDropzone();
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/vendor/styles/_appwork/include";
  .btn-continue {
    width: 50%;
    margin-top: 1em;
    height: 42px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
  }

  .btn-clear-data {
    align-self: flex-end;
    color: #f4374e
  }
</style>
